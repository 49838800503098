<template>
  <div class="content-list-content">
    <div class="content-list-table" style="border-radius: 0px">
      <div class="mb-4 row">
        <span
          class="col-sm-12 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold cpx-4"
        >
          オファー管理
        </span>
        <div
          class="col-sm-12 col-md-6 col-lg-6 tex-left text-md-right text-sm-left"
        >
          <button class="button-create-content" v-on:click="createContent()">
            コンテンツ新規登録
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <div class="row pt-3 mb-4">
        <div class="row-input col-sm-4 col-md-4 col-lg-6">
          <label class="w-100 font-weight-bold cpx-15">スラグ</label>
          <multiselect
            v-model="slagSelect"
            tag-placeholder="閲覧付与スラグを追加してください"
            class="col-sm-12 col-lg-12 cp-15"
            placeholder="閲覧付与スラグを追加してください"
            label="slag_name_en"
            track-by="slag_name_en"
            :options="listSlagOptions"
            :multiple="true"
            :taggable="false"
            :showNoResults="false"
            select-label="入力して選択する"
            selected-label="選択された"
            deselect-label="入力して削除する"
          >
            <template v-slot:no-options> リストは空です </template>
          </multiselect>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListContents()"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div>
      <Tables
        :items="listContent.data"
        :fields="fields"
        :itemsPerPage="50"
        style="margin-top: 100px"
      >
        <template v-slot:title="{ item }">
          <td style="min-width: 145px; width: 20%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.title }}
            </div>
          </td>
        </template>
        <template v-slot:description="{ item }">
          <td v-html="item.description"></td>
        </template>
        <template v-slot:end_date="{ item }">
          <td>
            {{
              new Date(item.end_date).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td>
            {{
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:ID="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <!-- <CButton
              class="mx-1 text-nowrap"
              color="primary"
              square
              v-on:click="editSaleContent(item.id)"
              variant="outline"
              size="sm"
            >
              編集
            </CButton> -->
            <!-- <CButton
              class="mx-1 text-nowrap"
              color="primary"
              square
              variant="outline"
              size="sm"
              v-on:click="editCatalogues(item.id)"
            >
              カバー編集
            </CButton> -->
            <!-- <CButton
              class="mx-1 text-nowrap"
              color="primary"
              square
              v-on:click="editContent(item.id, index + listContent.from)"
              variant="outline"
              size="sm"
            >
              販売編集
            </CButton> -->
            <div class="d-flex flex-row">
              <!-- <CButton
                class="mx-1 text-nowrap"
                color="primary"
                square
                v-on:click="copyStoreURL(item.id)"
                variant="outline"
                size="sm"
                style="margin-bottom: 5%"
              >
                ストアURLコピー
              </CButton> -->
              <button
                type="button"
                class="btn mx-1 text-nowrap btn-other"
                style="margin-bottom: 5%"
                v-b-tooltip.hover
                title="Tooltip directive content"
                v-on:click="copyStoreURL(item.id)"
              >
                ストアURLコピー
              </button>
              <button
                type="button"
                class="btn mx-1 text-nowrap btn-other"
                style="margin-bottom: 5%"
                v-b-tooltip.hover
                title="Tooltip directive content"
                v-on:click="copySalesURL(item.id)"
              >
                販売URLコピー
              </button>
              <!-- <CButton
                class="mx-1 text-nowrap"
                color="primary"
                square
                v-on:click="showConfirm(item.id)"
                variant="outline"
                size="sm"
                v-b-modal.delete-modal-content
              >
                販売URLコピー
              </CButton> -->
              <CButton
                class="mx-1 text-nowrap btn-del"
                color="danger"
                square
                v-on:click="showConfirm(item.id)"
                variant="outline"
                size="sm"
                v-b-modal.delete-modal-content
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listContent.total"
      >
        <p class="font-weight-bold">
          {{ listContent.total }}件中{{ listContent.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listContent.last_page"
      >
        <CPagination
          v-if="listContent.total"
          :activePage.sync="page"
          :pages="listContent.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="detail-modal-content" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>{{ contentById.title }}</h5>
        </div>
        <div>
          <CContainer>
            <CRow>
              <CCol lg="3" class="py-3">Description:</CCol>
              <CCol lg="9" class="py-3">{{ contentById.description }}</CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Image:</CCol>
              <CCol lg="9" class="py-3"
                ><img
                  width="150px"
                  src="https://picsum.photos/600/300/?image=25"
              /></CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Category:</CCol>
              <CCol lg="9" class="py-3">{{ contentById.category_name }}</CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Price:</CCol>
              <CCol lg="9" class="py-3">{{ contentById.price }}</CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Point:</CCol>
              <CCol lg="9" class="py-3">{{ contentById.point }}</CCol>
            </CRow>
            <CRow>
              <CCol lg="3" class="py-3">Cteate at:</CCol>
              <CCol lg="9" class="py-3">{{
                new Date(contentById.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}</CCol>
            </CRow>
          </CContainer>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="delete-modal-content" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、登録または決済URLがご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeContent(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-content')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "ListContent",
  components: {
    Tables,
  },
  data() {
    return {
      fields: tableFields.CONTENT,
      listChecked: [],
      dataModal: "",
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataContent: {
        id: null,
        shop_id: null,
      },
      limit: 50,
      page: 1,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      listSlagSelect: [],
      listSlagOptions: [],
      slagSelect: null,
    };
  },
  created() {
    const data = {
      shop_id: this.shop_id,
    };
    this.getALLlistSlag(data);
    this.getListContents(1);
  },
  computed: {
    ...mapGetters([
      "listContent",
      "contentById",
      "message",
      "success",
      "error",
      "listSlag",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.getListContents(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listContent() {
      this.page = this.listContent.current_page;
    },
    page() {
      this.getListContents(this.page);
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
  },
  methods: {
    ...mapActions({
      getListContent: "getListContent",
      getContentById: "getContentById",
      deleteContent: "deleteContent",
      getALLlistSlag: "getALLlistSlag",
    }),
    check(e, id) {
      if (e.target.checked) {
        this.listChecked.push(id);
      } else {
        this.listChecked = this.listChecked.filter(function (item) {
          return item !== id;
        });
      }
    },
    async contentDetail(id) {
      this.getContentById(id);
    },
    getListContents(page) {
      this.listSlagSelect = [];
      if (page === undefined) {
        page = this.page;
      }
      if (this.slagSelect !== null) {
        this.slagSelect.map((item) => {
          this.listSlagSelect.push(item.slag_id);
        });
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          slag_id: this.listSlagSelect,
        },
      };
      this.getListContent(formData);
    },
    createContent() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Content Change"
          : "Content Change domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    removeContent(id) {
      this.dataContent.id = id;
      this.dataContent.shop_id = this.shop_id;
      this.deleteContent(this.dataContent);
      this.$nextTick(() => {
        this.$bvModal.hide("delete-modal-content");
        this.getListContents(this.page);
      });
    },
    editContent(id, no) {
      localStorage.setItem(Constants.NO_CONTENT, no);
      this.$router.push({
        name: this.$route.params.shopId
          ? "Edit Content Change"
          : "Edit Content Change domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      this.getListContents(1);
    },
    returnDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    editSaleContent(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Content Sale"
          : "Content Sale domain",
        params: { id: id },
      });
    },
    editCatalogues(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Table Of ContentEdit"
          : "Table Of ContentEdit domain",
        params: { id: id },
      });
    },
    copyStoreURL(id) {
      const hostName = window.location.origin;
      if (this.$route.params.shopId) {
        const shop_id = this.shop_id;
        const copyText =
          hostName + "/shop/" + shop_id + "/store/" + id + "?keyword=";
        navigator.clipboard.writeText(copyText);
      } else {
        const copyText = hostName + "/store/" + id + "?keyword=";
        navigator.clipboard.writeText(copyText);
      }
    },
    copySalesURL(id) {
      const shop_id = this.shop_id;
      const hostName = window.location.origin;
      if (this.$route.params.shopId) {
        const copyText = hostName + "/shop/" + shop_id + "/buy/p/" + id;
        navigator.clipboard.writeText(copyText);
      } else {
        const copyText = hostName + "/buy/p/" + id;
        navigator.clipboard.writeText(copyText);
      }
    },
    resetFieldSearch() {
      this.slagSelect = [];
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          slag_id: [],
        },
      };
      this.getListContent(formData);
    },
  },
};
</script>
<style scoped></style>
